 //_pointHistory.scss 

 .point-history-blocks {

     border: 2px solid #EAEAEA;
     padding: 15px 10px;
     border-radius: 10px;
     background: whitesmoke;
     display: flex;

     .icon {
         svg {
             height: 28px;
             width: 28px;

             path {
                 fill: black;
             }
         }
     }


     .point-content {
         flex: 1;
     }


 }


 .la-input-icon-big {
     background-color: #d0d0d0;
     padding: 5px;
     border-radius: 100%;
     display: flex;

     svg {
         width: 28px;
         height: 28px;
     }
 }


 .point-history-date {
     color: #585858;
     opacity: 0.5;
     font-size: 14px;

     .ball{
        width: 10px;
        height: 10px;
        background-color: #9E9E9E;
        border-radius: 100%;
        display: inline-block;
        margin-left: 10px;
        margin-right: 10px;
     }

 }

 .point-history-text {
     font-size: 18px;
     color: #000;
     font-weight: normal;
     opacity: 0.5;
     

     .point-history-invoice{

        
     display: flex;
     gap: 10px;
     align-items: center;
     padding-top: 2px;

     .small-text{
        font-size: 16px;
     }
     .price-text{
        font-size: 16px;
        font-weight: bold;
     }

    }
 }

 .point-history-price {
    font-size: 18px;
    color: #4CAF50;
    font-weight: bold;
}
.invoice-button-download {
    border-radius: 5px;
    padding: 10px 10px;
    background-color: #4CAF50;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 10px;
    span{
        color: #fff;
        font-size: 14px;
        font-weight: 400;
    }
}

 .recharge {
     .la-input-icon-big {
         background-color: #8BC34A;
     }

     .point-history-price {
         color: #4CAF50;
     }
 }



 .revert {
     .la-input-icon-big {
         background-color: #4CAF50;
     }

     .point-history-price {
         color: #4CAF50;
     }
 }

 .referral {
    .la-input-icon-big {
        background-color: #53a8ed;
    }

    .point-history-price {
        color: #53a8ed;
    }
}



 .purches {
     .la-input-icon-big {
         background-color: #FFC107;
     }

     .point-history-price {
         color: #df2d2d;
     }
 }




 .history-top-box {
     display: block;
     height: 150px;
     // background-color:  $backed-primary-color;
    background-color:  #000;
     border-radius: 10px;
     position: relative;
     overflow: hidden;

     .back-shadow {
         display: block;
         width: 180px;
         height: 120px;
         position: absolute;
         transform: translate(-36%, -10px) rotate(45deg);
         background: linear-gradient(115deg, #ffed4a45, transparent);
     }

     .history-inner-box {
         display: flex;
         align-items: center;
         justify-content: center;
         height: 100%;
         color: #fff;
         padding-bottom: 10px;


         .history-inner-content {
             padding-right: 42px;

             .history-inner-title {
                 opacity: 0.8;
             }

             .history-inner-pints {
                 text-align: center;
                 font-size: 32px;
                 font-weight: bold;
             }
         }

         svg {
             margin-right: 20px;
             height: 48px;
             width: 48px;

             path {
                 fill: #FFEB3B;
             }
         }
     }

 }


 .add-point-parent-container {
     text-align: center;
     margin-top: -28px;
     position: relative;


     .add-point-container {
         display: inline-block;
         padding: 5px;
         background: #fff;
         border-radius: 5px;


         .add-point-button {
             background: $backed-primary-color;
             color: #fff;
             margin: 0;
             padding: 0;
             padding: 15px;
             border-radius: 5px;
             font-size: 16px;
             display: flex;
             align-items: center;

             svg {
                 width: 24px;
                 height: 24px;
                 margin-right: 10px;
             }
         }
     }
 }


 .point-recharge-main-container {

     .point-reacharge {

         font-family: $font-style-2;
         margin-left: -20px;
         padding: 10px;
         margin-right: -20px;
         margin-top: -20px;
         min-height: 120px;
         background: linear-gradient(180deg, rgba(50, 50, 50, 1) 0%, rgba(20, 20, 20, 1) 100%);

         .point-reacharge-header {

             display: flex;
             font-size: 28px;
             align-items: center;
             color: #fff;
             flex-direction: column-reverse;


             .points {
                 margin-top: 10px;
                 display: flex;
                 align-items: center;

                 .small {
                     font-size: 14px;
                     margin-left: 10px;
                     margin-right: -60px;
                     color: #ffeb3b;
                 }
             }

             .point-simble {
                 .point-symbol-container {
                     background-color: #272727;
                     border-radius: 100%;
                     padding: 10px;
                     display: flex;
                     margin-left: 10px;
                     box-shadow: 0 -3px 10px inset #000;
                     border: 1px solid #4c4c4c6e;
                     min-width: 64px;
                     min-height: 64px;

                     svg {

                         animation: animation-rotate 1s forwards;
                         width: 42px;
                         height: 42px;

                         path {
                             // fill: #FFEB3B;
                         }
                     }
                 }
             }

         }




     }

     .point-recharge-count {
         color: #ffffff70;
         opacity: 0.5;
         text-align: right;
         font-size: 14px;
         margin-top: 10px;
         font-style: italic;
         margin-right: 10px;


     }

     .point-recharge-dollar {
         display: flex;
     }

     .point-recharge-dollar {
         display: flex;
         justify-content: center;
         margin-top: 10px;
         margin-top: 25px;
         margin-bottom: 25px;

         .point-input-icon {
             padding: 10px;
             background: #0000000f;
             display: flex;
             border-radius: 100px;
             box-shadow: 0 3px 2px 0px #8b8b8b;
             transition: 200ms;

             &.point-recharge-minus {
                 svg {
                     path {
                         fill: #E91E63;
                     }
                 }
             }


             &.point-recharge-plus {
                 svg {
                     path {
                         fill: #4CAF50;
                     }
                 }
             }


             &:active,
             &:focus {
                 box-shadow: 0 3px 2px #0000004f inset;
             }

             svg {
                 path {
                     fill: #000;
                 }
             }
         }


         .point-recharge-input {

             position: relative;

             svg {
                 position: absolute;
                 right: 8px;
                 top: 50%;
                 transform: translate(-50%, -50%);

                 path {
                     fill: #b0b0b0;
                 }
             }

             input {
                 width: 100px;
                 height: 44px;
                 margin: 0 15px;
                 border-radius: 5px;
                 border: 2px solid #c7c7c7;
                 text-align: center;
                 font-size: 18px;
                 color: #0000009e;
                 font-weight: 600;
                 padding-right: 10px;
                 transition: 0.3;

                 &:focus{
                    outline: none;
                 }


                 &.error{
                    border: 2px solid #F44336;
                 }

             }
         }


     }


     .point-payment {
         margin-top: 20px;
         background-color: #e0e0e0;
         margin-left: -20px;
         margin-right: -20px;
         min-height: 100px;

         padding: 10px;

         .point-payment-container {

             .payment-items {
                 background-color: #cecece;
                 font-size: 18px;
                 padding: 10px;
                 display: flex;
                 align-items: center;
                 justify-content: center;
                 color: #9b9a9a;
                 border-radius: 10px;
                 margin-bottom: 10px;
                 transition: 0.3s;

                 &:active,
                 &:focus {
                     scale: 0.95;
                 }


                 &.selected {
                     background-color: #ffeb3b;
                     color: #000;
                 }

                 .payment-text {
                     flex: 1;
                     margin-left: 20px;
                     margin-right: 20px;
                 }

                 .payment-icon {
                     display: flex;
                     align-items: center;
                     font-size: 22px;
                 }

                 .payment-checkbox {
                     display: flex;
                     align-items: center;
                     font-size: 22px;
                 }



             }

         }

     }


     .error_active {
         background: #F44336;
         padding: 10px;
         border-radius: 5px;
         color: #fff;
         font-size: 14px;
         text-align: center;
     }


     .progressbar {
        height: 15px;
        background: #272727;
        width: 100%;
        margin-top: 10px;
        border: 1px solid #4c4c4c21;
        box-shadow: 0 -1px 5px #000 inset;
        border-radius: 100px;
        position: relative;
        margin-bottom: 10px;

        .progressbar-fill{
            position: absolute;
            height: 15px;
            width: 50%;
            border: 1px solid #000000;
            background-color: #FFC107;
            box-shadow: 0 -1px 5px #000 inset;
            border-radius: 100px;
            transition: 0.3s;
        }
    }


 }


 .change-history-select{
    margin-bottom: 10px;
    background-color: $backed-primary-color;
    display: flex;
    font-family: $font-style-2;
    padding: 10px;
    border-radius: 10px;

    .change-select-icon{
        display: flex;
        align-items: center;
        svg{
            width: 24px;
            height: 24px;
        }
    }
    .change-select-content{
        flex: 1;
        padding-left: 10px;
        font-size: 16px;
        font-weight: 500;
        color: #fff;
        display: flex;
        align-items: center;

    }
    .change-select-dropdownicon{
        display: flex;
        align-items: center;
        svg{
            width: 18px;
            height: 18px;
        }
    }
 }


 @keyframes animation-rotate {
     0% {
         transform: rotate(0) scale(0);
     }

     100% {
         transform: rotate(1080deg) scale(1);
     }


 }


 .strong-branch-title{
    font-weight: bold;
    margin-left: 10px;
    max-width: 120px;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    text-overflow: ellipsis;
 }

 .no-wrap{
    white-space: nowrap;
 }

 .la-input-text{
    width: 100% !important;
 }

 .opacity-5{
    opacity: 0.5;
 }

 .point-bonus{
    margin-left: -20px;
    margin-right: -20px;
    background: #fff;
    padding: 10px 20px;

    white-space: nowrap;
    overflow-x: auto;
    .bonus-container{
        display: inline-block;
        margin-right: 20px;
        padding: 10px;
        text-align: center;
        background: #FFEB3B;
        border-radius: 5px;
        opacity: 0.8;
        box-shadow: 0 2px 5px 0 #00000057;
        &.active{
            opacity: 1;
            outline: 5px #ffb604;
            background-color: #ffb604;
            outline-style: double;

            animation: shake 500ms;

        }
    }

 }

 @keyframes shake {
   /* 0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }

    */


    0% { transform: translateX(0) }
    12% { transform: translateX(5px) }
    25% { transform: translateX(-5px) }
    38% { transform: translateX(5px) }
    50% { transform: translateX(0) }
    62% { transform: translateX(5px) }
    75% { transform: translateX(-5px) }
    88% { transform: translateX(5px) }
    100% { transform: translateX(0) }


 }